import React, { useState, useEffect } from 'react';
import { Select } from '@chakra-ui/react';

function BaseTypeSelect({ apiPath, selectedOption, setSelectedOption, ...rest }) {
  const [options, setOptions] = useState([]);
  const { placeholder, ...cleanRest } = rest;
  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${apiPath}`;
    fetch(apiUrl, { method: "OPTIONS" })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((content) => {
        setOptions(content.actions.POST.kind.choices);
      })
      .catch((error) => {
        console.error('Error fetching options:', error);
      });
  }, [apiPath]);
  console.log(options)
  return (
    <Select onChange={(e) => setSelectedOption(e.target.value)} defaultValue={selectedOption} {...cleanRest}>
      { placeholder && <option disabled={cleanRest.required}>{placeholder}</option> }
      {options.map((_option) => {
        return (
          // If placeholder is defined, then other option must be removed. It's same as placeholder.
          placeholder && ["", "other"].indexOf(_option.value) > -1 ? null : 
          <option key={_option.value} value={_option.value}>
            {_option.display_name}
          </option>
        );
      })}
    </Select>
  );
}

export function TicketTypeSelect({option, setOption, ...rest}) {
  return (
    <BaseTypeSelect
      apiPath="public/tickets/"
      selectedOption={option}
      setSelectedOption={setOption}
      {...rest}
    />
  )
}

export default function TaskTypeSelect({option, setOption, ...rest}) {
  // Task types come from the tickets endpoint for now
  return (
    <TicketTypeSelect
      option={option}
      setOption={setOption}
      {...rest}
    />
  )
}
