import { Badge } from "@chakra-ui/react";
import React from "react";

export function translateState(state) {
  return (
    {
      assignment: "Atama Bekliyor",
      cancelled: "İptal Edildi",
      deleted: "SİLİNDİ",
      failed: "Servis Başarısız",
      in_queue: "Kuyrukta",
      in_progress: "İşlemde",
      approval: "Onay Bekliyor",
      completed: "Tamamlandı",
      pending: "Beklemede",
      sent_to_base: "Servise gönderiliyor",
      sent_to_reseller: "Bayiye gönderiliyor",
      received_by_base: "Servise ulaştı",
      received_by_reseller: "Bayiye ulaştı",
      eval_started: "İnceleniyor",
      pending_for_coverage: "Hizmet tutarı belirleniyor",
      customer_accepted: "Onarım ücreti müşteri tarafından onaylandı",
      customer_rejected: "Onarım ücreti müşteri tarafından onaylanmadı",
    }[state] || state
  );
}

export function translateKind(Kind) {
  // TODO: Backend is sending the options with their translations. Check if 
  // this function is still needed.
  return (
    {
      null: "Diğer",
      education: "Eğitim",
      initialization: "Başlatım",
      installation: "Kurulum",
      assembly: "Montaj",
      malfunction: "Arıza",
      replacement: "Değişim",
      maintenance: "Bakım",
      construction_site_control: "İnşaat Saha Kontrolü",
      request: "Talep",
      "": "Diğer",
    }[Kind] || Kind
  );
}

export function translateTableKind(TableKind) {
  return (
    {
      null: "Diğer",
      education: "Eğitim",
      initialization: "Başlatım",
      installation: "Kurulum",
      assembly: "Montaj",
      malfunction: "Arıza",
      replacement: "Değişim",
      maintenance: "Bakım",
      construction_site_control: "İnşaat Saha Kontrolü",
      request: "Talep",
      "": "Diğer",
    }[TableKind] || TableKind
  );
}

export function translateTableBadge(TableKind) {
  return (
    {
      null: <Badge colorScheme="purple">Diğer</Badge>,
      education: <Badge colorScheme="green">Eğitim</Badge>,
      initialization: <Badge colorScheme="purple">Başlatım</Badge>,
      installation: <Badge colorScheme="purple">Kurulum</Badge>,
      assembly: <Badge colorScheme="purple">Montaj</Badge>,
      malfunction: <Badge colorScheme="red">Arıza</Badge>,
      replacement: <Badge colorScheme="purple">Değişim</Badge>,
      maintenance: <Badge colorScheme="purple">Bakım</Badge>,
      construction_site_control: (
        <Badge colorScheme="purple">İnşaat Saha Kontrolü</Badge>
      ),
      request: <Badge colorScheme="green">Talep</Badge>,
      "": <Badge colorScheme="gray">Diğer</Badge>,
    }[TableKind] || TableKind
  );
}

export function translateType(Type) {
  return (
    {
      true: "onaylandı",
      false: "onaylanmadı",
      renovation: "Tamir/onarım",
      replacement: "Değişim",
      maintenence: "Bakım",
    }[Type] || Type
  );
}

export function translateCoveredBy(CovaredBy) {
  return (
    {
      guarantee: "Garanti Kapsamı",
      customer: "Kullanıcı hatası (Müşteri)",
      reseller: "Kurulum hatası (Bayi)",
    }[CovaredBy] || CovaredBy
  );
}

export function translateProjectKind(Kind) {
  return (
    {
      collective: "Toplu Konut",
      villa: "Villa",
      factory: "Fabrika",
      office: "Ofis",
      hotel: "Otel",
      eatery: "Yemekhane",
      bs_center: "İş Merkezi",
      pl_bldng: "Kamu Binası",
      sp_center: "Alışveriş Merkezi",
      other: "Diğer",
    }[Kind] || Kind
  );
}
