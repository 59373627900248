import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormHelperText,
  Heading,
  Image,
  Input,
  InputGroup,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { MdEmail, MdPhone } from "react-icons/md";
import CitySelect from "../../../components/select/CitySelect";
import { TicketTypeSelect } from "../../../shared/components/IssueTypeSelect";
import useInterval from "../../../helpers/useInterval";
import "./style.css";

const formatPhoneNumber = (number) => "+90" + number;

const RedButton = ({ children, ...props }) => {
  return (
    <Button
      fontFamily={"heading"}
      pl={10}
      pr={10}
      bgGradient="linear(to-r, red.900,red.700)"
      color={"white"}
      _hover={{
        bgGradient: "linear(to-r, red.800,red.700)",
        boxShadow: "xl",
      }}
      _disabled={{
        background: "gray.400",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export function SuccessBox({ ticketNumber }) {
  return (
    <Box>
      {" "}
      <Stack
        bg={"gray.100"}
        rounded={"xl"}
        minW={{ base: "340px", sm: "440px", md: "540px" }}
        p={{ base: 3, sm: 6, md: 8 }}
        spacing={{ base: 2 }}
        maxW={{ lg: "lg" }}
      >
        <Heading
          textAlign={"center"}
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          Servis kaydınız oluşturuldu
        </Heading>
        <Center>
          <Heading
            color={"red.900"}
            size="2xl"
            mt={{ base: 50, md: 60, lg: 70 }}
            mb={{ base: 50, md: 60, lg: 120 }}
          >
            {ticketNumber}
          </Heading>
        </Center>
        <Heading
          textAlign={"center"}
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "xl", sm: "xl", md: "xl" }}
        >
          Bu takip numarası ile istediğiniz zaman servis kaydınızın durumunu
          takip edebilirsiniz
        </Heading>
        <Center>
          <Link mt={4} href="/sorgu">
            Buraya tıklayarak sorgulama ekranına gidebilirsiniz.
          </Link>
        </Center>
      </Stack>
    </Box>
  );
}

export function Contact() {
  const [loading, setLoading] = useState(false);
  const [isFormButtonDisabled, setIsFormButtonDisabled] = useState(true);
  const [fullname, setFullname] = useState("");
  const [ticketNumber, setTicketNumber] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [kind, setKind] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [addres, setAddres] = useState("");
  const [ticketBody, setTicketBody] = useState("");
  const [errorData, setErrorData] = useState({});
  const [smsCode, setSmsCode] = useState("");
  const [smsToken, setSmsToken] = useState("");
  const [smsSecondsCounter, setSmsSecondsCounter] = useState();
  const [smsVerified, setSmsVerified] = useState(false);
  const firstInputAfterValidationRef = useRef(null);

  useInterval(() => {
    if (smsSecondsCounter > 0) {
      localStorage.setItem("countdown", smsSecondsCounter - 1);
      setSmsSecondsCounter(smsSecondsCounter - 1);
    }
  }, 1000);

  useEffect(() => {
    const storedCountdown = localStorage.getItem("countdown");
    if (storedCountdown) setSmsSecondsCounter(storedCountdown);
  }, []);

  const requestNumberToken = () => {
    setSmsSecondsCounter(60);
    setErrorData(Object.assign(errorData, { phone_number: [] }));
    fetch(`${process.env.REACT_APP_API_BASE_URL}public/phones/register/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ phone_number: formatPhoneNumber(phoneNumber) }),
    })
      .then(async (response) => {
        if (!response.ok) {
          setErrorData(await response.json());
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        setSmsToken(data.session_token);
        setIsFormButtonDisabled(false);
      })
      .catch(() => {
        setSmsSecondsCounter(3);
      });
  };

  const verifyNumber = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}public/phones/verify/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        security_code: smsCode,
        session_token: smsToken,
        phone_number: formatPhoneNumber(phoneNumber),
      }),
    })
      .then(async (response) => {
        return [await response.json(), response.ok];
      })
      .then((result) => {
        const [data, ok] = result;
        if (!ok) {
          setErrorData({ security_code: data["non_field_errors"] });
          return;
        }
        setErrorData(Object.assign(errorData, { security_code: [] }));
        if (data?.customer) {
          setFullname(
            data.customer.user.first_name + " " + data.customer.user.last_name
          );
          setEmail(data.customer.user.email);
          setCity(data.customer.city);
          setAddres(data.customer.address);
        }
        setSmsVerified(true);
        firstInputAfterValidationRef.current.focus();
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const handlePublicForm = () => {
    setLoading(true);
    const requestBody = {
      full_name: fullname,
      phone_number: formatPhoneNumber(phoneNumber),
      session_token: smsToken,
      security_code: smsCode,
      email: email,
      city: city,
      address: addres,
      body: ticketBody,
      assigned_to: null,
      kind: kind,
    };
    const requestoptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    };
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}public/tickets/`,
      requestoptions
    )
      .then(async (response) => {
        if (!response.ok) {
          setErrorData(await response.json());
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        if (data.number) {
          setTicketNumber(data.number);
        } else {
          setLoading(false);
          setTicketNumber(null);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (ticketNumber) {
    return <SuccessBox ticketNumber={ticketNumber} />;
  }

  return (
    <Box position={"relative"}>
      <Stack
        bg={"gray.50"}
        rounded={"xl"}
        minW={{ base: "280px", sm: "440px", md: "540px" }}
        p={{ base: 2, sm: 6, md: 8 }}
        spacing={{ base: 2 }}
        maxW={{ lg: "lg" }}
      >
        <Stack spacing={4}>
          <Heading
            textAlign={"center"}
            color={"gray.800"}
            lineHeight={1.1}
            fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
          >
            Servis Kaydı Oluştur
          </Heading>
          <Text
            textAlign={"center"}
            color={"gray.500"}
            fontSize={{ base: "sm", sm: "md" }}
          >
            <Text>
              <Link
                color="teal.500"
                target="_blank"
                href="https://inohom.com/kisisel_verilerin_korunmasi/inohom_kisisel_verilerin_korunması_ve_islenmesi_politikasi.pdf"
              >
                Gizlilik Politikası'nı
              </Link>{" "}
              ve{" "}
              <Link
                color="teal.500"
                target="_blank"
                href="https://inohom.com/kisisel_verilerin_korunmasi/inohom_aydinlatma_metni.pdf"
              >
                Hizmet Şartları'nı
              </Link>
            </Text>
            Formu göndererek onaylamış olursunuz
          </Text>
          <Box as={"form"} mt={10}>
            <Stack spacing={4}>
              <FormControl>
                <InputGroup gap={2}>
                  <Input
                    isInvalid={errorData.phone_number?.length > 0}
                    isDisabled={smsVerified}
                    type="tel"
                    placeholder="Telefon Numarası (5xxxxxxxxx)"
                    maxLength="17"
                    bg={"gray.100"}
                    border={0}
                    color={"gray.500"}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                  <RedButton
                    onClick={() => requestNumberToken()}
                    isDisabled={smsSecondsCounter > 0 || smsVerified}
                  >
                    Kod Al{" "}
                    {smsSecondsCounter > 0 &&
                      !smsVerified &&
                      " (" + smsSecondsCounter + ")"}
                  </RedButton>
                </InputGroup>
                {errorData.phone_number &&
                  errorData.phone_number.map((e) => (
                    <FormHelperText key={e} color="red">
                      {e}
                    </FormHelperText>
                  ))}
              </FormControl>
              <FormControl>
                <InputGroup gap={2}>
                  <Input
                    isInvalid={errorData.security_code?.length > 0}
                    isDisabled={smsVerified}
                    onChange={(e) => setSmsCode(e.target.value)}
                    placeholder="Doğrulama Kodu"
                    bg={"gray.100"}
                    color={"gray.500"}
                  />
                  <RedButton
                    onClick={verifyNumber}
                    isDisabled={smsVerified || !smsToken}
                  >
                    {!smsVerified ? "Doğrula" : "Doğrulandı"}
                  </RedButton>
                </InputGroup>
                {errorData.security_code &&
                  errorData.security_code.map((e) => (
                    <FormHelperText key={e} color="red">
                      {e}
                    </FormHelperText>
                  ))}
              </FormControl>
              <FormControl>
                <Input
                  type={"text"}
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  ref={firstInputAfterValidationRef}
                  placeholder="Ad Soyad"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                />
                {errorData.full_name &&
                  errorData.full_name.map((e) => (
                    <FormHelperText key={e} color="red">
                      {e}
                    </FormHelperText>
                  ))}
              </FormControl>
              <FormControl>
                <Input
                  type={"email"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-posta"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                />
                {errorData.email &&
                  errorData.email.map((e) => (
                    <FormHelperText color="red">{e}</FormHelperText>
                  ))}
                </FormControl>
              <FormControl>
                <InputGroup>
                  <CitySelect
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                </InputGroup>
                {errorData.city &&
                  errorData.city.map((e) => (
                    <FormHelperText color="red">{e}</FormHelperText>
                  ))}
              </FormControl>
              <FormControl>
                <Input
                  value={addres}
                  onChange={(e) => setAddres(e.target.value)}
                  placeholder="Adresinizi giriniz"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
                {errorData.address &&
                  errorData.address.map((e) => (
                    <FormHelperText color="red">
                      {e}
                    </FormHelperText>
                  ))}
              </FormControl>
              <FormControl>
                <TicketTypeSelect 
                  option={kind}
                  setOption={setKind}
                  placeholder="İşlem Türü"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                />
              </FormControl>
              <FormControl>
                <Textarea
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                  placeholder="Dilek ve şikayetinizi buraya yazınız."
                  value={ticketBody}
                  onChange={(e) => setTicketBody(e.target.value)}
                />
                {errorData.body &&
                  errorData.body.map((e) => (
                    <FormHelperText color="red">
                      {e}
                    </FormHelperText>
                  ))}
              </FormControl>
            </Stack>
            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, red.900,red.700)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, red.800,red.700)",
                boxShadow: "xl",
              }}
              isLoading={loading}
              loadingText="Oluşturuluyor.."
              isDisabled={isFormButtonDisabled}
              onClick={handlePublicForm}
            >
              Oluştur
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
export function TicketCreate() {
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, sm: 1, md: 1, lg: 2 }}
        spacing={{ base: 10, lg: 22 }}
        py={{ base: 14, sm: 12, lg: 20 }}
      >
        <Stack spacing={{ base: 8, md: 20 }}>
          <Box
            pt={{ base: 0, md: 20, lg: 10, xl: 20 }}
            display={"flex"}
            justifyContent={"space-evenly"}
            flexWrap={"wrap"}
            flexDirection={"column"}
            alignContent={"stretch"}
            alignItems="center"
          >
            <Image
              src={"https://inohom.b-cdn.net/newImages/inohomtrlogo.png"}
              width={{ base: "290px", sm: "350px", md: "400px" }}
            />
            <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
              <VStack spacing={1} alignItems="flex-start">
                <a href="mailto:teknikdestek@inohom.com">
                  <Button
                    size="md"
                    height="48px"
                    width="300px"
                    variant="ghost"
                    color="blackAlpha.900"
                    _hover={{ border: "2px solid #1C6FEB" }}
                    leftIcon={<MdEmail color="#660000" size="20px" />}
                  >
                    teknikdestek@inohom.com
                  </Button>
                </a>
                <a href="tel:+90 850 811 82 83">
                  <Button
                    size="md"
                    height="48px"
                    width="300px"
                    variant="ghost"
                    href="tel:+902762634397"
                    color="blackAlpha.900"
                    _hover={{ border: "2px solid #1C6FEB" }}
                    leftIcon={<MdPhone color="#650000" size="20px" />}
                  >
                    +90 850 811 82 83
                  </Button>
                </a>
              </VStack>
            </Box>
          </Box>
        </Stack>
        <Contact />
      </Container>
    </Box>
  );
}
